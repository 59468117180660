import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import { Box, Grid, Stack, Typography } from '@mui/material'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import {
  dateNowIsoString,
  IDHE_DATE_DISPLAY_FORMAT,
  isoDateToDisplayFormatWithTime,
} from 'common/utils/DateUtils'
import useProviders from 'modules/shared/hooks/useProviders'
import { evaluateLabelUtil } from 'modules/shared/StaffAssociation/StaffAssociationUtils'
import { IMMUNIZATION_SOURCE_OF_INFORMATION } from 'services/terminologyConstants'
import { selectChartingValidationActive } from 'store/selectors/charting'
import { selectTerminology } from 'store/selectors/terminology'
import { selectUserId } from 'store/selectors/user'
import { ImmunizationTemplateState, TImmunizationTemplateValue } from './ImmunizationTemplate'

type ImmunizationTemplateComponentProps = ImmunizationTemplateState & {
  onChange: (key: string, value: TImmunizationTemplateValue) => void
}

const ImmunizationTemplateComponent = ({
  administeringProvider,
  consent,
  createdBy,
  createdOn,
  date,
  dosage,
  immunization,
  lotExpiryDate,
  lotNumber,
  note,
  onChange,
  route,
  schedule,
  site,
  sourceOfInformation,
  units,
}: ImmunizationTemplateComponentProps) => {
  const { t } = useTranslation('common')
  const userId = useSelector(selectUserId)
  const terminology = useSelector(selectTerminology)
  const validationActive = useSelector(selectChartingValidationActive)
  const providers = useProviders()

  const [initialized, setInitialized] = useState(false)

  const sourceOfInfoOpts = useMemo(
    () =>
      terminology.find((term) => term.setName === IMMUNIZATION_SOURCE_OF_INFORMATION)?.value || [],
    [terminology]
  )

  const createdByProvider = useMemo(
    () => providers?.find((each) => each.id === createdBy),
    [createdBy, providers]
  )

  const loggedInProvider = useMemo(
    () => providers?.find((each) => each.userId === userId),
    [providers, userId]
  )

  const isTemplateEditor = useMemo(
    () => window.location.pathname.includes('admin/template-editor'),
    []
  )

  const handleChange = useCallback(
    (key: string, value: TImmunizationTemplateValue) => onChange(key, value),
    [onChange]
  )

  useEffect(() => {
    if (!isTemplateEditor) {
      if (loggedInProvider && loggedInProvider.id) {
        if (createdBy === undefined) handleChange('createdBy', loggedInProvider.id)
        if (createdOn === undefined) handleChange('createdOn', dateNowIsoString())
      }
    }
    setInitialized(true)
  }, [createdBy, createdOn, handleChange, isTemplateEditor, loggedInProvider])

  if (!initialized) return null

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography component="h1">{t('charting.templates.immunization.label')}</Typography>
          </Stack>
        </Grid>
        <Box width="100%" />
        <Grid item md={6} sm={12}>
          <MISSelectDropdown
            error={validationActive && !sourceOfInformation}
            helperText={
              validationActive && !sourceOfInformation
                ? t('charting.templates.immunization.fields.source-of-information-required')
                : undefined
            }
            label={t('charting.templates.immunization.fields.source-of-information')}
            noClearIcon
            onChange={(e) => handleChange('state', e.target.value)}
            options={sourceOfInfoOpts.map((each) => ({ label: each.name as string, value: each }))}
            required
            value={sourceOfInformation}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <DatePicker
            disablePast
            inputFormat={IDHE_DATE_DISPLAY_FORMAT}
            label={t('charting.templates.immunization.fields.date')}
            onChange={(value) => handleChange('date', value || '')}
            renderInput={(props) => <MISTextField {...props} />}
            value={date || null}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <MISTextField
            label={t('charting.templates.immunization.fields.lot-number')}
            onChange={(e) => handleChange('lotNumber', e.target.value)}
            value={lotNumber || ''}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <DatePicker
            disablePast
            inputFormat={IDHE_DATE_DISPLAY_FORMAT}
            label={t('charting.templates.immunization.fields.lot-expiry-date')}
            onChange={(value) => handleChange('lotExpiryDate', value || '')}
            renderInput={(props) => <MISTextField {...props} />}
            value={lotExpiryDate || null}
          />
        </Grid>
        <Grid item sm={12}>
          <MISTextField
            label={t('charting.templates.immunization.fields.note')}
            minRows={3}
            multiline
            onChange={(e) => handleChange('note', e.target.value)}
            required
            value={note || ''}
          />
        </Grid>
        {(createdBy || createdOn) && (
          <Grid item sm={12}>
            {createdBy && createdByProvider && (
              <Typography>{`${t(
                'charting.templates.follow-up.fields.created-by'
              )}${evaluateLabelUtil(createdByProvider.names)}`}</Typography>
            )}
            {createdOn && (
              <Typography>{`${t(
                'charting.templates.follow-up.fields.created-on'
              )}${isoDateToDisplayFormatWithTime(createdOn)}`}</Typography>
            )}
          </Grid>
        )}
      </Grid>
    </LocalizationProvider>
  )
}

export default ImmunizationTemplateComponent
