import { Component } from 'react'
import { CodedConceptDto, PersonnelDTO, ProgramTerse } from 'services/openapi'
import FollowUpTemplateComponent from './FollowUpTemplateComponent'
import { ETemplateMetadataType, ITemplate, ITemplateMetadata } from '../../blots/TemplateBlot'

export enum EFollowUpTemplateStateValue {
  Pending = 'Pending',
  Resolved = 'Resolved',
  Voided = 'Voided',
  Cancelled = 'Cancelled',
}

export type TFollowUpTemplateValue =
  | string
  | CodedConceptDto
  | PersonnelDTO
  | ProgramTerse[]
  | EFollowUpTemplateStateValue

export type FollowUpTemplateState = {
  assignedTo?: PersonnelDTO
  createdBy?: string
  createdOn?: string
  description?: string
  dueDate?: string
  programs?: ProgramTerse[]
  priority?: CodedConceptDto
  state?: EFollowUpTemplateStateValue
}

export type FollowUpTemplateProps = { data: FollowUpTemplateState }

class FollowUpTemplate
  extends Component<FollowUpTemplateProps, FollowUpTemplateState>
  implements ITemplate
{
  type = 'FollowUpTemplate'
  state: FollowUpTemplateState

  constructor(props: FollowUpTemplateProps) {
    super(props)
    this.state = { ...props.data }
  }

  static getMetadata = (): ITemplateMetadata => ({
    assignedTo: { label: 'Assigned to', type: ETemplateMetadataType.PersonnelDTO },
    createdBy: { label: 'Created by', type: ETemplateMetadataType.ProviderId },
    createdOn: { label: 'Created on', type: ETemplateMetadataType.DateTime },
    description: { label: 'Description', required: true, type: ETemplateMetadataType.string },
    dueDate: { label: 'Due date', type: ETemplateMetadataType.Date },
    priority: { label: 'Priority', required: true, type: ETemplateMetadataType.CodedConceptDto },
    programs: { isArray: true, label: 'Programs', type: ETemplateMetadataType.ProgramTerse },
    state: { label: 'State', type: ETemplateMetadataType.string },
  })

  static getTemplateDisplayName = (): string => 'Follow Up Template'

  static getTemplateVersion = (): number => 1

  getData = () => ({
    assignedTo: this.state.assignedTo,
    createdBy: this.state.createdBy,
    createdOn: this.state.createdOn,
    description: this.state.description,
    dueDate: this.state.dueDate,
    priority: this.state.priority,
    programs: this.state.programs,
    state: this.state.state,
  })

  handleChange = (key: string, value: TFollowUpTemplateValue) =>
    this.setState({ ...this.state, [key]: value })

  render() {
    return <FollowUpTemplateComponent {...this.state} onChange={this.handleChange} />
  }
}

export default FollowUpTemplate
