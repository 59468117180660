import { Component } from 'react'
import { CodedConceptDto, PersonnelDTO } from 'services/openapi'
import ImmunizationTemplateComponent from './ImmunizationTemplateComponent'
import { ETemplateMetadataType, ITemplate, ITemplateMetadata } from '../../blots/TemplateBlot'

export type TImmunizationTemplateValue =
  | number
  | string
  | CodedConceptDto
  | PersonnelDTO
  | undefined

export type ImmunizationTemplateState = {
  sourceOfInformation?: CodedConceptDto
  date?: string
  immunization?: string
  schedule?: string
  consent?: CodedConceptDto
  administeringProvider?: string
  dosage?: number
  units?: CodedConceptDto
  site?: CodedConceptDto
  route?: CodedConceptDto
  lotNumber?: string
  lotExpiryDate?: string
  note?: string
  createdBy?: string
  createdOn?: string
}

export type ImmunizationTemplateProps = { data: ImmunizationTemplateState }

class ImmunizationTemplate
  extends Component<ImmunizationTemplateProps, ImmunizationTemplateState>
  implements ITemplate
{
  type = 'ImmunizationTemplate'
  state: ImmunizationTemplateState

  constructor(props: ImmunizationTemplateProps) {
    super(props)
    this.state = { ...props.data }
  }

  static getMetadata = (): ITemplateMetadata => ({
    administeringProvider: {
      label: 'Administering Provider',
      type: ETemplateMetadataType.ProviderId,
    },
    consent: { label: 'Consent', type: ETemplateMetadataType.CodedConceptDto },
    createdBy: { label: 'Created by', type: ETemplateMetadataType.ProviderId },
    createdOn: { label: 'Created on', type: ETemplateMetadataType.DateTime },
    date: { label: 'Date', type: ETemplateMetadataType.Date },
    dosage: { label: 'Dosage', type: ETemplateMetadataType.number },
    immunization: { label: 'Immunization', type: ETemplateMetadataType.string },
    lotExpiryDate: { label: 'Lot Expiry Date', type: ETemplateMetadataType.Date },
    lotNumber: { label: 'Lot Number', type: ETemplateMetadataType.string },
    note: { label: 'Note', type: ETemplateMetadataType.string },
    route: { label: 'Route', type: ETemplateMetadataType.CodedConceptDto },
    schedule: { label: 'Schedule', type: ETemplateMetadataType.string },
    site: { label: 'Site', type: ETemplateMetadataType.CodedConceptDto },
    sourceOfInformation: {
      label: 'Source of Information',
      type: ETemplateMetadataType.CodedConceptDto,
    },
    units: { label: 'Units', type: ETemplateMetadataType.CodedConceptDto },
  })

  static getTemplateDisplayName = (): string => 'Immunization Template'

  static getTemplateVersion = (): number => 1

  getData = () => ({
    administeringProvider: this.state.administeringProvider,
    consent: this.state.consent,
    createdBy: this.state.createdBy,
    createdOn: this.state.createdOn,
    date: this.state.date,
    dosage: this.state.dosage,
    immunization: this.state.immunization,
    lotExpiryDate: this.state.lotExpiryDate,
    lotNumber: this.state.lotNumber,
    note: this.state.note,
    route: this.state.route,
    schedule: this.state.schedule,
    site: this.state.site,
    sourceOfInformation: this.state.sourceOfInformation,
    units: this.state.units,
  })

  handleChange = (key: string, value: TImmunizationTemplateValue) =>
    this.setState({ ...this.state, [key]: value })

  render() {
    return <ImmunizationTemplateComponent {...this.state} onChange={this.handleChange} />
  }
}

export default ImmunizationTemplate
