import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import MISTabbedContentContainer from 'common/components/contentpane/MISTabbedContentContainer'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { expertiseAtom, personnelDetailsState } from 'recoil/personnelDetails'
import {
  ApiError,
  PersonnelControllerService,
  PersonnelDTO,
  UserRoleControllerService,
} from 'services/openapi'
import { selectUserId } from 'store/selectors/user'
import BusinessAddress from './BusinessAddress/BusinessAddress'
import ProviderContactsSection from './Contacts/ContactsSection'
import CredentialsTraining from './CredentialsTraining/CredentialsTraining'
import Identifiers from './Identifiers/IdentifiersSection'
import JobFunction from './JobFunction/JobFunction'
import NamesSection from './Names/NamesSection'
import Profile from './Profile/Profile'
import User from './User/User'
import ProviderStaffHeader from '../ProviderStaffHeader'
import './ProviderStaffDetails.scss'

const ProviderStaffDetails = () => {
  const { t } = useTranslation('common')
  const { providerStaffId } = useParams()
  const { handleApiError } = useErrorHandler()
  const [personnelData, setPersonnelData] = useRecoilState(personnelDetailsState)
  const [isManager, setIsManager] = useState(false)
  const userId = useSelector(selectUserId)
  const setExpertiseValues = useSetRecoilState(expertiseAtom)

  const tabConfig = useCallback(
    () => [
      {
        active: false,
        component: <Profile />,
        dirty: false,
        disabled: false,
        id: 'profile',
        label: 'Profile',
        visibile: true,
      },
      {
        active: false,
        component: <NamesSection />,
        dirty: false,
        disabled: false,
        id: 'names',
        label: 'Names',
        visibile: true,
      },
      {
        active: false,
        component: <JobFunction />,
        dirty: false,
        disabled: false,
        id: 'job-function',
        label: 'Job Functions',
        visibile: true,
      },
      {
        active: false,
        component: <ProviderContactsSection />,
        dirty: false,
        disabled: false,
        id: 'contact-information',
        label: 'Contact Information',
        visibile: true,
      },
      {
        active: false,
        component: <BusinessAddress />,
        dirty: false,
        disabled: false,
        id: 'business-addresses',
        label: t('provider-staff.business-addresses.header.title'),
        visibile: true,
      },
      {
        active: false,
        component: <Identifiers />,
        dirty: false,
        disabled: false,
        id: 'identifiers',
        label: 'Identifiers',
        visibile: true,
      },
      {
        active: false,
        component: <CredentialsTraining />,
        dirty: false,
        disabled: false,
        id: 'credentials',
        label: t('provider-staff.credentials-training.header.title'),
        visibile: true,
      },
      {
        active: false,
        component: <User isAdmin={isManager} />,
        dirty: false,
        disabled: false,
        id: 'user',
        label: t('provider-staff.user.header.title'),
        visibile: true,
      },
    ],
    [isManager, t]
  )

  useEffect(() => {
    const getExpertiseValues = async () => {
      try {
        const response = await PersonnelControllerService.getAllExpertises()
        if (response) {
          setExpertiseValues(response.content)
        }
      } catch (error) {
        handleApiError(error)
      }
    }
    getExpertiseValues()
  }, [setExpertiseValues, handleApiError])

  useEffect(() => {
    const getProviderStaffDetails = async (personnelId: string) => {
      await PersonnelControllerService.getPersonnel(personnelId)
        .then((response: PersonnelDTO) => setPersonnelData(response))
        .catch((error: ApiError) => handleApiError(error))
    }
    if (providerStaffId && providerStaffId !== personnelData?.id) {
      getProviderStaffDetails(providerStaffId)
    }
  }, [handleApiError, personnelData?.id, providerStaffId, setPersonnelData])

  useEffect(() => {
    const getUserRoles = async (userId: string) => {
      const resp = await UserRoleControllerService.listUserRoles(userId, true)
      if (resp.content) {
        const managementRole = resp.content.find((x) => x.role?.name === 'Management')
        if (managementRole) {
          setIsManager(true)
        }
      }
    }

    setIsManager(false)
    if (personnelData?.userId) getUserRoles(personnelData?.userId)
  }, [personnelData, userId])

  return (
    <MISBaseContainer>
      <div className="provider-staff-record-details">
        <ProviderStaffHeader />
        <MISTabbedContentContainer tabConfig={tabConfig()} />
      </div>
    </MISBaseContainer>
  )
}

export default ProviderStaffDetails
