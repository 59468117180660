/*
 * Terminology constants for fetching value sets.
 */
export const MIS_GENDER_VOCAB_NAME = 'MisBcGender'
export const MEMBER_IDENTIFIER_TYPE_VOCAB_NAME = 'Member Identifier Types'
export const MEMBER_STATUS_VOCAB_NAME = 'MemberStatus'
export const PERSON_NAME_USE_VOCAB_NAME = 'PersonNameUse'
export const MIS_MEMBER_PRONOUNS_VOCAB_NAME = 'MisMemberPronouns'
export const MIS_MARITAL_STATUS = 'Mustimuhw Marital Status types'
export const MIS_ETHNICITY = 'Mustimuhw Person Ethnicity types'
export const MIS_YESNONOTASKED = 'Mustimuhw YesNoNotAsked types'
export const MIS_EMPLOYMENT = 'Mustimuhw Employment types'
export const MIS_AFFORDS_BASIC_NEEDS = 'AbleToAffordBasicNeeds'
export const MIS_HOUSEHOLD_INCOME = 'Mustimuhw Household Income types'
export const MIS_HIGHEST_EDUCATION = 'Mustimuhw Highest Education types'
export const MIS_EDUCATION_GRADE_LEVEL = 'EducationGradeLevel'
export const MIS_LANGUAGE_VOCAB_NAME = 'Mustimuhw Languages'
export const MIS_PERSON_LANGUAGE_FLUENCY_VOCAB_NAME = 'Mustimuhw Fluency types'
export const MIS_VOID_REASON_TYPE = 'VoidReasonType'
export const MIS_NOT_PROVIDED_ESDRT_REASON_TYPE = 'ReasonNotProvidedeSDRT'
export const MIS_NOT_PROVIDED_REASON_TYPE = 'ReasonNotProvided'
export const MIS_VOIDED_REASON_TYPE = 'VoidedReasonType'
export const MIS_ENCOUNTER_NOTE_STATE = 'ServiceNoteStatus'
export const MIS_ENCOUNTER_SERVICE_STATE = 'EncounterServiceState'
export const MIS_ENCOUNTER_STATE = 'EncounterState'
export const MIS_LACK_OF_TRANSPORTATION = 'LackOfTransportation'
export const MIS_IDENTIFIED_DISABILITY = 'IdentifiedDisability'
export const SDOH_HIGHEST_EDUCATION = 'HighestLevelEducation'
export const MIS_YESNONOTASKED_PREFERNOT = 'YesNoNotAskedUnknownPreferNot'
export const SDOH_HOUSEHOLD_INCOME = 'HouseholdIncome'
export const FOLLOW_UP_URGENCY = 'FollowUpUrgency'

/**
 * ADDRESS
 */
export const ADDRESS_AREA_TYPES = 'Mustimuhw Area types'
export const ADDRESS_COUNTRIES = 'Address Countries'
export const ADDRESS_PROVINCES = 'Address Provinces'
export const ADDRESS_TYPES = 'Mustimuhw Address types'
export const ADDRESS_STREET_TYPES = 'Mustimuhw Street types'
export const ADDRESS_UNIT_TYPES = 'Mustimuhw Unit types'
/**
 * GOVERNANCE AGENCY
 */
export const REPORTING_COMMUNITY_IDENTIFIERS = 'ReportingCommunityIdentifierTypes'
export const GOVERNANCE_AGENCY_TYPES = 'GovernanceAgencyTypes'
export const CONTACT_TYPES = 'ContactTypes'
export const CONTACT_DETAIL_TYPES = 'ContactDetailTypes'
export const DURATION_TYPES = 'Mustimuhw Duration types'
export const SIGN_REQ_TYPES = 'Mustimuhw Signature Requirement types'
export const PROV_SERV_TYPES = 'Mustimuhw Provincial Service types'
export const REFERRAL_TYPES = 'Mustimuhw Referral types'
export const MILEAGE_TYPES = 'Mustimuhw Mileage types'
export const GOV_AGENCY_RELATIONSHIP_TYPES = 'Mustimuhw Gov Agency Relation types'
/**
 * IDENTIFIERS
 */
export const ESDRT_COMMUNITY_NAME = 'eSDRTCommunityName'
export const MEDICAL_TRANSPORTATION_AGREEMENT_NUMBER = 'medicalTransportationAgreementNumber'
export const FNIH_REGION_NAME = 'FNIHRegionName'
export const FNIH_REGION_NUMBER = 'FNIHRegionNumber'
export const FNIH_COMMUNITY_NAME = 'FNIHCommunityName'
export const PANORAMA_AORG_ID = 'panoramaAORGId'
export const BAND_ID = 'bandId'
export const GOV_AGENCY_IDENTIFIERS = 'GovAgencyIdentifierType'
/**
 * CONTACTS
 */
export const MIS_CONTACT_METHODS = 'Mustimuhw Preferred Contact Methods'
export const MIS_CONTACT_PHONE_TYPES = 'Mustimuhw Phone Types'
export const MIS_CONTACT_EMAIL_TYPES = 'Mustimuhw Email Types'
export const MIS_CONTACT_SOCIAL_MEDIA_TYPES = 'Mustimuhw Social Media Types'
/**
 * PROVIDERS
 */
export const MIS_PROVIDER_FNIH_SPECIALTY_TYPES = 'BC Provider FNIH Specialty'
export const MIS_PROVIDER_IDENTIFIER_TYPES = 'Provider Identifier Types'
export const MIS_PROVIDER_ROLE_TYPES = 'HealthCare Provider Role Types'
export const MIS_PROVIDER_QUALIFIED_ROLE_TYPE = 'QualifiedRoleType'

export const MIS_PERSON_RELATIONSHIP_TYPE = 'PersonRelationshipType'
export const MIS_PERSON_RELATIONSHIP_ATTRIBUTE = 'PersonRelationshipAttribute'
export const MIS_SUPPORT_RELATIONSHIP_ATTRIBUTE = 'SupportRelationshipAttribute'

export const MIS_MILEAGE_UNITS = 'MileageUnits'
export const MIS_LENGTH_UNITS = 'LengthUnits'
export const MIS_TEMPERATURE_UNITS = 'TemperatureUnits'
export const MIS_TIME_UNITS = 'TimeUnits'
export const MIS_WEIGHT_UNITS = 'WeightUnits'

export const BIRTH_SEX = 'BirthSex'
export const DELIVERY_METHODS = 'DeliveryMethods'
export const YES_NO_UNKNOWN = 'YesNoUnknown'
export const YES_NO_NOT_ASKED_UNKNOWN_PREFER_NOT = 'YesNoNotAskedUnknownPreferNot'
export const INFANT_COLOUR_FINDINGS = 'InfantColourFindings'
export const INFANT_FEEDING_TYPES = 'InfantFeedingTypes'
export const CAUSE_OF_DEATH_TYPES = 'CauseOfDeathTypes'
export const CAUSE_OF_DEATH_PREGNANY_PERIOD_TYPES = 'CauseOfDeathPregnantPeriodTypes'
export const DEATH_DETAIL_OBSERVATION_CATEGORY = 'DeathDetailsObservationCategory'

export const MIS_KIND_OF_DOC = 'KindOfDoc'
export const MIS_DOCUMENT_ROLES = 'DocumentRoles'
export const MIS_DOCUMENT_SETTING = 'DocumentSetting'
export const MIS_DOC_SUBJECT_MATTER = 'DocSubjectMatter'
export const MIS_SERVICE_TYPES_FOR_DOCUMENT = 'DocTypeOfService'
export const MIS_DOCUMENT_TITLE = 'DerivedOntologyNames'
export const MIS_BC_IMMUNIZATION_AGENT = 'BCImmunizationAgent'
export const MIS_CONSENT_OPTIONS = 'ConsentTypes'
export const MIS_CONSENT_BY_OPTIONS = 'ConsentBys'
export const MIS_DIRECTIVE_ACCESS_TYPES = 'Directive Access Types'
export const MIS_DIRECTIVE_ACCESS_PERSONNEL_TYPES = 'Directive Access Personnel Types'

export const HEALTH_CONCERN_TYPES = 'Health Concern Types'
export const HEALTH_CONCERN_CERTAINTY_TYPES = 'Health Concern Certainty Types'

export const LIFE_STAGE_CODES = 'LifeStageCodes'
export const ALLERGY_INTOLERANCE_SEVERITY_CODES = 'AllergyIntoleranceSeverityCodes'
export const ALLERGY_INTOLERANCE_TYPE_CODES = 'AllergyIntoleranceTypeCodes'
export const REQUESTED_BY_CODES = 'RequestedByCodes'

export const IMMUNIZATION_SOURCE_OF_INFORMATION = 'ImmunizationSourceOfInformation'

export const getVocabularyAtoms = () => {
  return [
    ADDRESS_AREA_TYPES,
    ADDRESS_COUNTRIES,
    ADDRESS_PROVINCES,
    ADDRESS_TYPES,
    ADDRESS_STREET_TYPES,
    ADDRESS_UNIT_TYPES,
    CONTACT_TYPES,
    CONTACT_DETAIL_TYPES,
    DURATION_TYPES,
    GOVERNANCE_AGENCY_TYPES,
    MEMBER_IDENTIFIER_TYPE_VOCAB_NAME,
    MEMBER_STATUS_VOCAB_NAME,
    MILEAGE_TYPES,
    GOV_AGENCY_RELATIONSHIP_TYPES,
    MIS_GENDER_VOCAB_NAME,
    MIS_MEMBER_PRONOUNS_VOCAB_NAME,
    PERSON_NAME_USE_VOCAB_NAME,
    PROV_SERV_TYPES,
    REFERRAL_TYPES,
    REPORTING_COMMUNITY_IDENTIFIERS,
    SIGN_REQ_TYPES,
    MIS_MARITAL_STATUS,
    MIS_ETHNICITY,
    MIS_YESNONOTASKED,
    MIS_EMPLOYMENT,
    MIS_AFFORDS_BASIC_NEEDS,
    MIS_HOUSEHOLD_INCOME,
    MIS_HIGHEST_EDUCATION,
    MIS_EDUCATION_GRADE_LEVEL,
    MIS_PERSON_LANGUAGE_FLUENCY_VOCAB_NAME,
    MIS_CONTACT_METHODS,
    MIS_CONTACT_PHONE_TYPES,
    MIS_CONTACT_EMAIL_TYPES,
    MIS_CONTACT_SOCIAL_MEDIA_TYPES,
    MIS_PROVIDER_FNIH_SPECIALTY_TYPES,
    MIS_PROVIDER_IDENTIFIER_TYPES,
    MIS_PROVIDER_ROLE_TYPES,
    MIS_PROVIDER_QUALIFIED_ROLE_TYPE,
    MIS_PERSON_RELATIONSHIP_TYPE,
    MIS_PERSON_RELATIONSHIP_ATTRIBUTE,
    MIS_SUPPORT_RELATIONSHIP_ATTRIBUTE,
    MIS_VOID_REASON_TYPE,
    MIS_NOT_PROVIDED_ESDRT_REASON_TYPE,
    MIS_NOT_PROVIDED_REASON_TYPE,
    MIS_VOIDED_REASON_TYPE,
    GOV_AGENCY_IDENTIFIERS,
    MIS_MILEAGE_UNITS,
    MIS_LENGTH_UNITS,
    MIS_TEMPERATURE_UNITS,
    MIS_TIME_UNITS,
    MIS_WEIGHT_UNITS,
    BIRTH_SEX,
    DELIVERY_METHODS,
    YES_NO_UNKNOWN,
    YES_NO_NOT_ASKED_UNKNOWN_PREFER_NOT,
    INFANT_COLOUR_FINDINGS,
    INFANT_FEEDING_TYPES,
    CAUSE_OF_DEATH_TYPES,
    CAUSE_OF_DEATH_PREGNANY_PERIOD_TYPES,
    DEATH_DETAIL_OBSERVATION_CATEGORY,
    MIS_KIND_OF_DOC,
    MIS_DOCUMENT_ROLES,
    MIS_DOCUMENT_SETTING,
    MIS_DOC_SUBJECT_MATTER,
    MIS_SERVICE_TYPES_FOR_DOCUMENT,
    MIS_DOCUMENT_TITLE,
    MIS_BC_IMMUNIZATION_AGENT,
    MIS_CONSENT_OPTIONS,
    MIS_ENCOUNTER_NOTE_STATE,
    MIS_ENCOUNTER_SERVICE_STATE,
    MIS_ENCOUNTER_STATE,
    MIS_CONSENT_BY_OPTIONS,
    MIS_DIRECTIVE_ACCESS_TYPES,
    MIS_DIRECTIVE_ACCESS_PERSONNEL_TYPES,
    HEALTH_CONCERN_TYPES,
    HEALTH_CONCERN_CERTAINTY_TYPES,
    MIS_LACK_OF_TRANSPORTATION,
    MIS_IDENTIFIED_DISABILITY,
    SDOH_HIGHEST_EDUCATION,
    MIS_YESNONOTASKED_PREFERNOT,
    SDOH_HOUSEHOLD_INCOME,
    FOLLOW_UP_URGENCY,
    LIFE_STAGE_CODES,
    ALLERGY_INTOLERANCE_SEVERITY_CODES,
    ALLERGY_INTOLERANCE_TYPE_CODES,
    REQUESTED_BY_CODES,
    IMMUNIZATION_SOURCE_OF_INFORMATION,
  ]
}

export const getVocabularyWithRelationsAtoms = () => {
  return [MIS_LANGUAGE_VOCAB_NAME]
}

// const MIS_CODE_SYSTEM_OID = '2.16.840.1.113883.3.1019.1.8';
export const VALUESET = {
  MEMBER_STATUS: {
    ACTIVE: {
      code: '272730231000087108',
      codeSystemOid: '2.16.840.1.113883.6.96',
    },
    DECEASED: {
      code: '419099009',
      codeSystemOid: '2.16.840.1.113883.6.96',
    },
  },
}
